import React, {useState} from 'react';

import Home from './components/Home';
import Navbar from './components/Navbar';


//import Contact from './components/Contact';

function App() {
  const [nav, setNav] = useState(false);
  return (
    <div>
      
      <Navbar setNav={setNav} />
      <Home nav={nav} />
        
    </div>
  );
}

export default App;
