import React from 'react';
import supply_Svg from '../assets/SupplyChain.svg';
import market_Presence from '../assets/market_Presence.svg';
import csr from '../assets/Csr3.svg';
import future_outlook from '../assets/future_Outlook.svg';

const Operations = () => {
  return (
    <div className='w-full  px-4 bg-white' id="operations">
        <h2 className='text-4xl text-gray-700 font-bold text-center mb-8' >Our Specialities</h2>
        <div className='max-w-[1240px] mt-2 mx-auto grid md:grid-cols-4 gap-8'>
            <div className='w-full shadow-xl flex flex-col p-2 my-4 rounded-lg hover:scale-105 duration-300'>
                <img className='w-26 h-24 mx-auto mt-2 h-150 bg-white' src={supply_Svg} alt="/" />
                <h2 className='text-2xl font-bold  text-gray-700 text-center py-4'>Supply Chain</h2>
                <div className='text-center font-medium'>
                    <p className='py-2 border-b  text-gray-700 mx-8 mt-8'>We have an established Robust supply chain</p>
                    <p className='py-2 border-b  text-gray-700 mx-8'>Secure and Efficient delivery to clients</p>
                    <p className='py-2 border-b  text-gray-700 mx-8'>Dedicated logistics team  manages the transportation of ore from our mines to processing plants and, ultimately, to our clients globally.
</p>
                </div>
                <button className='bg-[#292C27] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'></button>
            </div>
            <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
                <img className='w-26 h-24 mx-auto mt-2 h-100 bg-white' src={market_Presence} alt="/" />
                <h2 className='text-2xl font-bold  text-gray-700 text-center py-4'>Market Presence</h2>
                <div className='text-center font-medium'>
                    <p className='py-2 border-b  text-gray-700 mx-8 mt-8'>
                    Highly sorted after iron ore in the steel manufacturing industry. </p>
                    <p className='py-2 border-b  text-gray-700 mx-8'> We supply to major steel factories within Kenya and have a growing international client base</p>
                    <p className='py-2 border-b  text-gray-700 mx-8'>Commitment to quality, reliability makes us your preferred partner.
                    </p>
                </div>
                <button className='bg-[#292C27] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'></button>
            </div>
            <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
                <img className='w-26 h-24 mx-auto mt-2 h-100 bg-white' src={csr} alt="/" />
                <h2 className='text-2xl font-bold  text-gray-700 text-center py-4'>CSR</h2>
                <div className='text-center font-medium'>
                    <p className='py-2 border-b  text-gray-700 mx-8 mt-8'>
                        Active engagement in community development projects</p>
                    <p className='py-2 border-b  text-gray-700 mx-8'> Investment in local infrastructure, education, and healthcare</p>
                    <p className='py-2 border-b  text-gray-700 mx-8'>Translating mining activities into tangible benefits for the people of Taita Taveta County.
                    </p>
                </div>
                <button className='bg-[#292C27] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'></button>
            </div>
            <div className='w-full shadow-xl  text-gray-700 bg-gray-100 flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300'>
                <img className='w-26 h-24 mx-auto mt-2 bg-transparent' src={future_outlook} alt="/" />
                <h2 className='text-2xl font-bold text-center py-8'>Future Outlook</h2>
                
                <div className='text-center font-medium'>
                    <p className='py-2 border-b mx-8 mt-8'>Expand Operations with the upward Trajectory of global steel industry</p>
                    <p className='py-2 border-b mx-8'>Exploration of New Mining Sites</p>
                    <p className='py-2 border-b mx-8'>Increase Production Capacity to meet rising demand for high grade iron ore</p>
                </div>
                <button className='bg-[#292C27] text-black w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'></button>
            </div>            
        </div>
    </div>
  );
};

export default Operations;
