import React from 'react';
import logo_haem from '../assets/ore-9.png';
import logo_magne from '../assets/ore-10.png';


const Cards = () => {
  return (
    <div className='w-full  px-4 bg-white' id='product'>
        <h2 className='text-4xl text-gray-700 font-bold text-center mb-8'>Products</h2>
        <div className='max-w-[1240px] mt-2 mx-auto grid md:grid-cols-2 gap-2'>
            
            <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
                <img className='mx-auto mt-2 h-40 w-40 bg-transparent rounded-full' src={logo_haem} alt="/" />
                <h2 className='text-2xl font-bold text-gray-700 text-center py-2'>Haematite Iron Ore</h2>
                <div className='text-center font-medium'>
                    <p className='py-2 border-b text-gray-700 mx-8 mt-8'>High Iron Content</p>
                    <p className='py-2 border-b  text-gray-700 mx-8'>High Purity levels</p>
                    <p className='py-2 border-b  text-gray-700 mx-8'>Ideal For Steel Production</p>
                </div>
                <button className='bg-[#292C27] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'></button>
            </div>
            <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
                <img className='mx-auto mt-2 h-40 w-40 bg-transparent rounded-full' src={logo_magne} alt="magnetite logo" />
                <h2 className='text-2xl font-bold  text-gray-700 text-center py-2'>Magnetite Iron Ore</h2>
                <div className='text-center font-medium'>
                    <p className='py-2 border-b mx-8  text-gray-700 mt-8'>Known for Distingished Magnetic Properties</p>
                    <p className='py-2 border-b  text-gray-700 mx-8'>High Iron Content</p>
                    <p className='py-2 border-b  text-gray-700 mx-8'>Ideal for Metallurgical Processes</p>
                </div>
                <button className='bg-[#292C27] text-black w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'></button>
            </div>
            
        </div>
    </div>
  );
};

export default Cards;
