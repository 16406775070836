import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaTwitterSquare,
} from 'react-icons/fa';


import logoFooter from '../assets/logo12.jpg';

const Footer = () => {
  return (  
    
    
    <div className='max-w-[1240px] mx-auto py-8 px-4 grid lg:grid-cols-2 gap-8 text-gray-700'>
      <div>
        {/*<img src={logo_footer} height={300} width={300} alt="logo for footer" className='flex rounded-full' />*/}
        <img src={logoFooter}  alt="logo for footer" className='flex rounded-lg' />
      </div>
      
      <div>

        <p className='py-4 font-bold'>DILOTIKO</p>

        <div className='flex hover:font-bold hover:scale-110 duration-300 justify-start space-x-4'>
          <ul className='flex gap-4 justify-start space-x-4'>
            <Link to="/"><li className='p-4 hover:scale-110 duration-300'>Home</li></Link>
            <Link to="#company"><li className='p-4 hover:scale-110 duration-300'>Company</li></Link>
            <Link to="#about"><li className='p-4 hover:scale-110 duration-300'>About</li></Link>
            <Link to="#operations"><li className='p-4 hover:scale-110 duration-300'>Speciality</li></Link>
            <Link to='#product'><li className='p-4 hover:scale-110 duration-300'>Products</li></Link>
          </ul>
        </div>
        <p className='py-4 font-bold' id='contact'>Contact Us</p>
        <div className='flex justify-start space-x-4'>
          <ul className='flex gap-8 justify-start space-x-4'>
              <li className='py-2 text-gray-700 text-sm'>Phone: +254 711958557</li>
              <li className='py-2 text-gray-700 text-sm'>Email: Operations@dilotiko.net || Finance@dilotiko.net</li>
          </ul>
        </div>
        <div className='flex justify-between md:w-[75%] my-6'>
          <FaFacebookSquare size={30} />
          <FaInstagram size={30} />
          <FaTwitterSquare size={30} />
          <FaGithubSquare size={30} />
          <FaDribbbleSquare size={30} />
        </div>    
      </div>  
    </div>
  
  );
}

export default Footer;
