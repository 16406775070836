import React from 'react';

const Hero = ({ nav }) => {
  return (
    <div className={`max-container text-white padding-container pt-3 flex flex-col gap-20 py-10 pb-32 md:gap-28 lg:py-20 xl:flex-row ${nav ? 'hidden' : ''}`}>
      <div className='relative text-gray-700 ml-8 gap-2 z-20 flex flex-1 flex-col xl:w-1/2'>
        <h1 className='bold-52 font-bold text-gray-700 lg:bold-500'> DILOTIKO LIMITED </h1>
        <p className='regular-16 mt-6 text-gray-700 xl:max-w-[520]'>
          We're a prominent player in the mining industry, focusing on the extraction <br />
          of high-grade iron ore in Taita Taveta County, Kenya.<br />        
          Established in 2016, Dilotiko has quickly risen to become a key supplier of iron ore to steel factories both domestically and internationally.
        </p>
      </div>
      <div className={`relative flex flex-1 items-start ${nav ? 'hidden' : ''}`}>
        <div className='relative z-20 w-[268px] ml-8 flex flex-col gap-4 rounded-3xl hover:scale-110 duration-300 bg-green-90 px-7 py-8'>
          <div className='flex flex-col'>
            <div className='flexBetween'>
              <p className='regular-16 text-gray-20'> DILOTIKO </p>
            </div>
            <div className='flex flex-col'>
              <p className='regular-16 block text-gray-20'>Founded</p>
              <p className='bold-20 text-white'>2016</p>
            </div>
            <div className='flex flex-col'>
              <p className='regular-16 block text-gray-20'>Location</p>
              <p className='bold-20 text-white'>Taita Taveta County, Kenya</p>
            </div>
            <div className='flex flex-col'>
              <p className='regular-16 block text-gray-20'>Industry</p>
              <p className='bold-20 text-white'>Mining and Minerals</p>
            </div>
            <div className='flex flex-col'>
              <p className='regular-16 block text-gray-20'>Products</p>
              <p className='bold-20 text-white'>High-grade Haematite and Magnetite Iron Ore</p>
            </div>
            <div className='flex flex-col'>
              <p className='regular-16 block text-gray-20'>Mining Deposits</p>
              <p className='bold-20 text-white'>22,000 metric tonnes</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
