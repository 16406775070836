import React from 'react';
import logo_Operations from '../assets/ore-7.png';

const Newsletter = () => {
  return (
    <div className='w-full bg-white py-16 px-4' id='about'>
      <div className=' max-w-[1240px] mx-auto grid md:grid-cols-2  hover:scale-105 duration-300'>
        <img className='w-[500px] mx-auto rounded-lg bg-white my-4' src={logo_Operations} alt='/' /> 
        <div className='flex flex-col justify-center'>
          <p className='text-black font-bold '>  </p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl text-gray-700 font-bold py-2'>About Us</h1>
          <p className='text-gray-700'>
          Dilotiko Ltd operates a state-of-the-art mining facility in Taita Taveta County, home to one of the richest iron ore deposits in the region. With an estimated deposit of 15 million tonnes, we specialize in extracting both Haemitite and Magnetite iron ore. Our mining processes adhere to the highest standards of safety and environmental conservation.

          

          </p>
          <button className=' bg-green-90  text-white w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Learn More</button>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
