import React, { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { HashLink as Link } from 'react-router-hash-link';
import Logo from '../assets/logo12.jpg';

const Navbar = () => {
  const [nav, setNav] = useState(false);

  useEffect(() => {
    // Set initial state of nav to true when component mounts
    setNav(true);
  }, []);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className='relative'>
      <div className='flex justify-between items-center h-26 mx-auto px-2 w-full text-gray-700 cursor-pointer'>
        <img src={Logo} height={200} width={200} className='rounded-lg bg-transparent hover:scale-110 duration-300 ml-4 pl-2 pt-4 pb-2 mt-4' alt="Logo" />
        <div>
          <h1 className='w-full text-3xl hover:font-bold font-bold text-white'>DILOTIKO Limited</h1>
          <p className='text-xl font-medium text-white'>Your high grade minerals partner</p>
        </div>
        <ul className={`md:flex hover:font-bold text-gray-700 ${nav ? 'hidden' : ''}`}>
          <Link to="/"><li className='p-4 hover:scale-110 duration-300'>Home</li></Link>
          <Link to="#company"><li className='p-4 hover:scale-110 duration-300'>Company</li></Link>
          <Link to="#about"><li className='p-4 hover:scale-110 duration-300'>About</li></Link>
          <Link to="#operations"><li className='p-4 hover:scale-110 duration-300'>Speciality</li></Link>
          <Link to='#product'><li className='p-4 hover:scale-110 duration-300'>Products</li></Link>
          <Link to='#contact'><li className='p-4 hover:scale-110 duration-300'>Contact</li></Link>
        </ul>
        <div onClick={handleNav} className='block md:hidden z-20'>
          {nav ? <AiOutlineMenu size={20} /> : <AiOutlineClose size={20} />}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
