import React from 'react';

import Analytics from './Analytics';
import Cards from './Cards';
import Footer from './Footer';
import Hero from './Hero';
import Newsletter from './Newsletter';

import Operations from './Operations';

function Home({nav}) {
  return (
    <div>
      <Hero nav={nav} />
      <Analytics />
      <Newsletter />
      <Cards />
      <Operations />
      <Footer />

        
        
        
      
        
    </div>
  )
}
export default Home;