import React from 'react';
//import Laptop from '../assets/laptop.jpg';
//import Mine from '../assets/mine.png';
import logo_Mission from '../assets/ore-6.png'

const Analytics = () => {
  return (
    <div className='w-full bg-white py-16 px-4' id='company'>
      <div className=' max-w-[1240px] mx-auto grid md:grid-cols-2  hover:scale-105 duration-300'>
        <div className='relative'>
          
          <img className='w-[500px] h-500 mx-auto my-4 rounded-lg mix-blend-mode[multiply]'src={logo_Mission} alt='/' /> 
          
        </div>

        <div className='flex flex-col justify-center'>
          <p className='text-black font-bold '>  </p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl text-gray-700 font-bold py-2'>Mission Statement</h1>
          <p className='text-gray-700'>
          Dilotiko Ltd is committed to harnessing the natural wealth of Taita Taveta County by responsibly extracting high-grade iron ore to meet the growing global demand for steel. We strive to operate sustainably, ensuring that our activities benefit our community and the environment.

          </p>
          <button className=' bg-green-90  text-white w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Learn More</button>
        </div>
        
      </div>

    </div>
  );
};

export default Analytics;
